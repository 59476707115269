import React, { type FC, useState } from 'react'
import { IconCopy } from '@tabler/icons-react'
import copy from 'copy-to-clipboard'

interface CopyProps {
  text: string
}
const CopyToClipboard: FC<CopyProps> = ({ text }) => {
  const [copied, setCopied] = useState(false)
  const onCopy = (): void => {
    const success = copy(text)
    if (success) {
      setCopied(true)
    }
  }
  return (
    <span
      className={`copy-to-clipboard` + (copied ? ` copied` : ``)}
      onClick={onCopy}
    >
      <IconCopy />
    </span>
  )
}

export default CopyToClipboard
