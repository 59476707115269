import React, { type FC } from 'react'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import { IconChevronsRight } from '@tabler/icons-react'

interface Props {
  title?: string
  to: string
}
const ViewDetailsLink: FC<Props> = ({ title, to }) => {
  if (title == null) {
    title = intl.get('common.details')
  }

  return (
    <Link to={to} title={title}>
      <IconChevronsRight />
    </Link>
  )
}

export default ViewDetailsLink
