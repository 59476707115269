import { appDomain } from 'shared/model'
import { type Outcome } from 'shared/types'
import { attach, createEvent, createStore } from 'effector'
import { loadOutcomesFx } from 'entities/outcome/model'
import { createGate } from 'effector-react'

export const $outcomes = appDomain.createStore<Outcome[]>([])
export const OutcomeListGate = createGate('Outcome List Gate')
export const onPageClicked = createEvent<number>()

export const onOutcomeRefresh = createEvent()
export const onPageSizeChanged = createEvent<number>()
export const $outcomePaginatorPage = createStore(0)
export const $outcomePaginatorPageSize = createStore(10)
export const $outcomePaginatorTotal = createStore(0)
export const loadOutcomesListFx = attach({
  effect: loadOutcomesFx
})
