import { sample } from 'effector'
import { $isAppInitialized, AuthenticatedGate } from './store'
import { $$merchant } from 'entities/merchant'
import { $$token } from 'entities/token'
import { $$user } from 'entities/user'
import { and, not } from 'patronum'
import { $$rates } from 'entities/rate'
import { $isAuthenticated } from 'entities/user/model'

sample({
  clock: AuthenticatedGate.open,
  filter: and($isAuthenticated, not($isAppInitialized)),
  target: [
    $$merchant.loadMerchantsFx,
    $$token.loadTokensFx,
    $$user.getMeFx,
    $$rates.loadRatesFx
  ]
})
