import { appDomain } from 'shared/model'
import type * as types from 'shared/types'
import { api } from 'shared/api'
import { createEvent, sample } from 'effector'

export const loadWebhooksFx = appDomain.createEffect(api.webhook.getWebhooks)

export const resendWebhook = createEvent<types.Uuid>()

const resendWebhookFx = appDomain.createEffect(api.webhook.resendWebhook)

export const $resendInProgress = resendWebhookFx.pending

export interface UseWebhooksProps {
  isLoading: boolean
  total: number
  perPage: number
  page: number
  webhooks: types.Webhook[]
}

sample({
  clock: resendWebhook,
  target: resendWebhookFx
})
