import React, { type FC } from 'react'

interface ContentProps {
  children: React.ReactNode
  position?: 'left' | 'right'
  classes?: string
}
export const Content: FC<ContentProps> = ({
  children,
  position = 'left',
  classes = ''
}) => {
  return (
    <div className={`data-block ` + position + ` ` + classes}>{children}</div>
  )
}
