import React, { type FC } from 'react'
import { type IconColorProps } from './IconColorProps'

const Danger: FC<IconColorProps> = ({ color = '#ff0000' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2 3H15.8C16.1 3 16.3 3.1 16.5 3.3L21.2 8C21.4 8.2 21.5 8.4 21.5 8.7V15.3C21.5 15.6 21.4 15.8 21.2 16L16.5 20.7C16.3 20.9 16.1 21 15.8 21H9.2C8.9 21 8.7 20.9 8.5 20.7L3.8 16C3.6 15.8 3.5 15.6 3.5 15.3V8.7C3.5 8.4 3.6 8.2 3.8 8L8.5 3.3C8.7 3.1 8.9 3 9.2 3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2 3H15.8C16.1 3 16.3 3.1 16.5 3.3L21.2 8C21.4 8.2 21.5 8.4 21.5 8.7V15.3C21.5 15.6 21.4 15.8 21.2 16L16.5 20.7C16.3 20.9 16.1 21 15.8 21H9.2C8.9 21 8.7 20.9 8.5 20.7L3.8 16C3.6 15.8 3.5 15.6 3.5 15.3V8.7C3.5 8.4 3.6 8.2 3.8 8L8.5 3.3C8.7 3.1 8.9 3 9.2 3Z"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8V12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8V12"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4999 16H12.5099"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4999 16H12.5099"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Danger
