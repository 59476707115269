import React, { type FC } from 'react'
import { type IconColorProps } from './IconColorProps'

const Pending: FC<IconColorProps> = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.5 8V12L14.5 14" stroke={color} strokeWidth="1.5" />
      <path
        d="M12.5 8V12L14.5 14"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path
        d="M2.80391 10.924C2.76193 11.336 3.06196 11.7041 3.47405 11.7461C3.88613 11.7881 4.25421 11.488 4.29619 11.076L2.80391 10.924ZM4.75452 14.7426C4.6124 14.3536 4.18179 14.1534 3.79272 14.2955C3.40365 14.4376 3.20346 14.8682 3.34558 15.2573L4.75452 14.7426ZM2.80005 20C2.80005 20.4142 3.13584 20.75 3.55005 20.75C3.96426 20.75 4.30005 20.4142 4.30005 20H2.80005ZM3.55005 15V14.25C3.13584 14.25 2.80005 14.5858 2.80005 15H3.55005ZM8.55005 15.75C8.96426 15.75 9.30005 15.4142 9.30005 15C9.30005 14.5858 8.96426 14.25 8.55005 14.25V15.75ZM4.29619 11.076C4.72146 6.90095 8.21316 3.71046 12.4095 3.66253L12.3924 2.16263C7.43306 2.21928 3.30651 5.98986 2.80391 10.924L4.29619 11.076ZM12.4095 3.66253C16.6058 3.6146 20.1695 6.72451 20.69 10.8887L22.1784 10.7027C21.5633 5.78133 17.3517 2.10599 12.3924 2.16263L12.4095 3.66253ZM20.69 10.8887C21.2105 15.0529 18.522 18.9443 14.443 19.9308L14.7956 21.3888C19.6163 20.2229 22.7936 15.624 22.1784 10.7027L20.69 10.8887ZM14.443 19.9308C10.364 20.9173 6.1944 18.6845 4.75452 14.7426L3.34558 15.2573C5.04726 19.9158 9.97498 22.5546 14.7956 21.3888L14.443 19.9308ZM4.30005 20V15H2.80005V20H4.30005ZM3.55005 15.75H8.55005V14.25H3.55005V15.75Z"
        fill={color}
      />
      <path
        d="M2.80391 10.924C2.76193 11.336 3.06196 11.7041 3.47405 11.7461C3.88613 11.7881 4.25421 11.488 4.29619 11.076L2.80391 10.924ZM4.75452 14.7426C4.6124 14.3536 4.18179 14.1534 3.79272 14.2955C3.40365 14.4376 3.20346 14.8682 3.34558 15.2573L4.75452 14.7426ZM2.80005 20C2.80005 20.4142 3.13584 20.75 3.55005 20.75C3.96426 20.75 4.30005 20.4142 4.30005 20H2.80005ZM3.55005 15V14.25C3.13584 14.25 2.80005 14.5858 2.80005 15H3.55005ZM8.55005 15.75C8.96426 15.75 9.30005 15.4142 9.30005 15C9.30005 14.5858 8.96426 14.25 8.55005 14.25V15.75ZM4.29619 11.076C4.72146 6.90095 8.21316 3.71046 12.4095 3.66253L12.3924 2.16263C7.43306 2.21928 3.30651 5.98986 2.80391 10.924L4.29619 11.076ZM12.4095 3.66253C16.6058 3.6146 20.1695 6.72451 20.69 10.8887L22.1784 10.7027C21.5633 5.78133 17.3517 2.10599 12.3924 2.16263L12.4095 3.66253ZM20.69 10.8887C21.2105 15.0529 18.522 18.9443 14.443 19.9308L14.7956 21.3888C19.6163 20.2229 22.7936 15.624 22.1784 10.7027L20.69 10.8887ZM14.443 19.9308C10.364 20.9173 6.1944 18.6845 4.75452 14.7426L3.34558 15.2573C5.04726 19.9158 9.97498 22.5546 14.7956 21.3888L14.443 19.9308ZM4.30005 20V15H2.80005V20H4.30005ZM3.55005 15.75H8.55005V14.25H3.55005V15.75Z"
        fill="white"
        fillOpacity="0.2"
      />
    </svg>
  )
}

export default Pending
