import { appDomain } from 'shared/model'
import { api } from 'shared/api'
import { sample } from 'effector'
import type * as types from 'shared/types'

export const loadOutcomesFx = appDomain.createEffect(api.outcome.getOutcomes)

export const deleteWithdrawFx = appDomain.createEffect(
  api.outcome.deleteWithdraw
)

export const deleteRefundFx = appDomain.createEffect(api.outcome.deleteRefund)
export const confirmRefundFx = appDomain.createEffect(api.outcome.confirmRefund)

export const onDeleteWithdraw = appDomain.createEvent<types.Uuid>()
export const onDeleteRefund = appDomain.createEvent<types.Uuid>()

sample({
  clock: onDeleteWithdraw,
  target: deleteWithdrawFx
})

sample({
  clock: onDeleteRefund,
  target: deleteRefundFx
})

export interface UseOutcomesProps {
  isLoading: boolean
  total: number
  perPage: number
  page: number
  outcomes: types.Outcome[]
}
