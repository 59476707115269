import { createGate } from 'effector-react'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import { activeMerchantChanged } from 'entities/merchant/model'
import { $$wallet } from 'entities/wallet'
import { $$operations } from 'entities/operation'
import { reset } from 'patronum'
import { redirect, ROUTES } from 'shared/router'



export const AppGate = createGate('App Gate')
// clear all loaded invoices, wallets, operations
// when user changes active merchant
reset({
  clock: activeMerchantChanged,
  target: [$$wallet.$wallets, $$operations.$operations]
})

redirect(activeMerchantChanged, ROUTES.root)
