import React, { type FC } from 'react'
import { Link } from 'react-router-dom'
import SidebarMenu from './sidebar-menu'
import { MerchantSelector } from 'features/merchant/merchant-selector'
import { useUnit } from 'effector-react'
import { $merchants } from 'entities/merchant/model'
import { ArrowLeft } from 'shared/icons'
import LogoSrc from 'shared/i/logo.svg'
import { onShortenMenuClick } from 'pages/layouts/model'

const Sidebar: FC = () => {
  const merchants = useUnit($merchants)

  return (
    <div className="menu">
      <div className="head">
        <Link className="logo mainlogo" to="/">
          <img src={LogoSrc} alt="" />
        </Link>
        <span
          className="hide-menu"
          onClick={() => {
            onShortenMenuClick()
          }}
        >
          <ArrowLeft />
        </span>
      </div>
      {merchants.length > 0 && <MerchantSelector />}
      {merchants.length > 0 && <SidebarMenu />}
    </div>
  )
}

export default Sidebar
