import { createStore } from 'effector'
import { type Rate } from 'shared/types/rate'
import { appDomain } from 'shared/model'
import { api } from 'shared/api'
import { useStoreMap } from 'effector-react'

export const $rates = createStore<Rate[]>([])
export const loadRatesFx = appDomain.createEffect(api.directory.rates)

$rates.on(loadRatesFx.doneData, (_, rates) => rates)

export const getRate = (baseAsset: string, quoteAsset: string): number | null =>
  useStoreMap({
    store: $rates,
    keys: [baseAsset, quoteAsset],
    fn: (rates, [baseAsset, quoteAsset]) => {
      const r = rates.find(
        (rate) =>
          rate.base_asset === baseAsset && rate.quote_asset === quoteAsset
      )
      if (r?.value != null) return r.value
      return null
    }
  })

export const convertRate = (
  baseAsset: string,
  quoteAsset: string,
  amount: number,
  precision: number = 2
): string => {
  const rate = getRate(baseAsset, quoteAsset)
  if (rate === null) return '0'
  return (rate * amount).toFixed(precision)
}
