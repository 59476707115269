import { sample, split } from 'effector'
import {
  appDomain,
  onAccessDeniedResponse,
  onAppError,
  onLogoutDone,
  onNetworkErrorResponse,
  onPageNotFoundResponse,
  onUnauthenticatedResponse,
  onUserErrorResponse
} from './domain'
import { debounce } from 'patronum'
import { errors } from '../api'

sample({
  clock: onUnauthenticatedResponse,
  target: onLogoutDone
})

const DEBOUNCE_NETWORK_ERROR_TIMEOUT_IN_MS = 500
debounce({
  source: onNetworkErrorResponse,
  timeout: DEBOUNCE_NETWORK_ERROR_TIMEOUT_IN_MS
})

appDomain.onCreateEffect((effect) => {
  effect.fail.watch(({ error }) => {
    if (error instanceof Error) {
      onAppError(error)
    }
  })
})

split({
  source: onAppError,
  match: {
    0: (err) => err instanceof errors.NetworkError,
    401: (err) => err instanceof errors.AuthenticationError,
    403: (err) => err instanceof errors.ForbiddenError,
    404: (err) => err instanceof errors.PageNotFoundError
  },
  cases: {
    0: /* onNetworkErrorResponse */ onUserErrorResponse,
    401: onUnauthenticatedResponse,
    403: onAccessDeniedResponse,
    404: onPageNotFoundResponse,
    __: onUserErrorResponse
  }
})
