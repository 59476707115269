import React, { type FC } from 'react'
import { IconSquareRoundedX } from '@tabler/icons-react'
import intl from 'react-intl-universal'

interface HeaderProps {
  title: string
  onCloseClick: () => void
}
const Header: FC<HeaderProps> = ({ title, onCloseClick }) => {
  return (
    <>
      <h1>{intl.get(title)}</h1>
      <i className="close" onClick={onCloseClick}>
        <IconSquareRoundedX />
      </i>
    </>
  )
}

export default Header
