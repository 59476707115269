import { type LoggedUser } from 'shared/api/tasks/auth'
import { useUnit } from 'effector-react'
import { $isAuthenticated, $user } from './store'

interface Props {
  isAuthenticated: boolean
  user: LoggedUser
}
export const useAuth = (): Props => {
  const isAuthenticated = useUnit($isAuthenticated)
  const user = useUnit($user)

  return { isAuthenticated, user }
}
