import { api } from 'shared/api'
import type * as types from 'shared/types'
import { appDomain, onLogoutDone } from 'shared/model/domain'
import { persist } from 'effector-storage/local'
import { combine } from 'effector'

export const $merchants = appDomain.createStore<types.IMerchant[]>([], {
  name: 'User merchants'
})

export const onMerchantClose = appDomain.createEvent<types.Uuid>(
  'Close merchant event'
)

export const $activeMerchantUuid = appDomain.createStore<types.Uuid>('', {
  name: 'Active merchant'
})

$activeMerchantUuid.reset(onLogoutDone)

persist({
  store: $activeMerchantUuid,
  key: 'merchant_uuid'
})



export const activeMerchantChanged = appDomain.createEvent<types.Uuid>(
  'Active merchant changed'
)

export const loadMerchantsFx = appDomain.createEffect(api.merchant.getAll)

export const closeMerchantFx = appDomain.createEffect(api.merchant.close)

export const createMerchantFx = appDomain.createEffect(api.merchant.create)
export const updateMerchantFx = appDomain.createEffect(api.merchant.update)

export const loadMerchantFx = appDomain.createEffect(api.merchant.getOne)

export const sendToModerationFx = appDomain.createEffect(api.merchant.moderate)

export const $merchantsLoaded = appDomain.createStore(false)

export const $activeMerchantUuidIsInMerchantsList = combine(
  $merchants,
  $activeMerchantUuid,
  $merchantsLoaded,
  (merchants, activeMerchantUuid, loaded) => {
    return loaded
      ? merchants.find((m) => m.uuid === activeMerchantUuid) !== undefined
      : true
  }
)

export const $activeMerchant = combine(
  $merchants,
  $activeMerchantUuid,
  (merchants, activeMerchantUuid) => {
    return merchants.find((m) => m.uuid === activeMerchantUuid)
  }
)
