import React, { type FC } from 'react'
import intl from 'react-intl-universal'

interface PageHeaderProps {
  title: string
}
export const PageHeader: FC<PageHeaderProps> = ({ title }) => {
  return (
    <div className="tpl_rubric">
      <h1>{intl.get(title)}</h1>
    </div>
  )
}
