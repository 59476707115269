import { createBrowserHistory, type Location } from 'history'
import {
  appDomain,
  onAccessDeniedResponse,
  onPageNotFoundResponse
} from 'shared/model/domain'
import { type Event, restore, sample } from 'effector'
import { ROUTES } from '../config'

export const browserHistory = createBrowserHistory()
export const locationUpdated = appDomain.createEvent<Location>()
export const $location = restore(locationUpdated, browserHistory.location)

export const $locationPathname = $location.map((location) => location.pathname)
export const $locationSearch = $location.map((location) => location.search.slice(1))

export const $locationSearchParams = $locationSearch.map((s) => new URLSearchParams(s))
browserHistory.listen((location) => {
  locationUpdated(location.location)
})

export const redirectFx = appDomain.createEffect((path: string) => {
  browserHistory.push(path)
})

export const reloadFx = appDomain.createEffect({
  handler() {
    document.location = ''
  }
})

export const redirectToLoginFx = appDomain.createEffect(
  'Redirect ro login page',
  {
    handler() {
      browserHistory.push(ROUTES.login)
    }
  }
)

export function redirect(event: Event<any>, path: string): any {
  sample({
    clock: event,
    fn: () => path,
    target: redirectFx
  })
}
redirect(onAccessDeniedResponse, ROUTES.access_denied)
redirect(onPageNotFoundResponse, ROUTES.page_not_found)
