import React, { type FC } from 'react'
import { type IconColorProps } from './IconColorProps'

const PowerButton: FC<IconColorProps> = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.50002 6C5.01405 8.09915 4.10395 11.5276 5.22152 14.5833C6.33909 17.639 9.24634 19.6714 12.5 19.6714C15.7537 19.6714 18.6609 17.639 19.7785 14.5833C20.8961 11.5276 19.986 8.09915 17.5 6"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M7.50002 6C5.01405 8.09915 4.10395 11.5276 5.22152 14.5833C6.33909 17.639 9.24634 19.6714 12.5 19.6714C15.7537 19.6714 18.6609 17.639 19.7785 14.5833C20.8961 11.5276 19.986 8.09915 17.5 6"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path d="M12.5 4V12" stroke={color} strokeWidth="1.5" />
      <path
        d="M12.5 4V12"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default PowerButton
