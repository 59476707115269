import React, { type ReactElement } from 'react'
import { withProviders } from './providers'
import './index.scss'
import AppRoutes from './app-routes'
import { useGate } from 'effector-react'
import { AppGate } from './model'
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

const App = (): ReactElement | null => {
  useGate(AppGate)
  return (
    <div className="app">
      <ReactNotifications />
      <AppRoutes />
    </div>
  )
}

export default withProviders(App)
