import React, { type FC, useEffect, useRef, useState } from 'react'
import intl from 'react-intl-universal'
import { Link } from 'react-router-dom'
import { ROUTES } from 'shared/router'
import { ConfirmDialog } from 'shared/ui'
import { logoutClicked } from '../logout/model'
import { $$user } from 'entities/user'
import { listenForOutsideClicks } from 'shared/lib'
import { IconLogout, IconShoppingCart } from '@tabler/icons-react'

const ProfileDropDown: FC = () => {
  const { user } = $$user.useAuth()

  const [isOpen, setIsOpen] = useState(false)
  const [listening, setListening] = useState(false)

  const componentRef = useRef<HTMLElement>(null)

  const onLogoutClick = async (): Promise<void> => {
    const confirmed = await ConfirmDialog(intl.get('common.confirm_exit'))
    if (confirmed) {
      logoutClicked()
    }
  }

  useEffect(
    listenForOutsideClicks(listening, setListening, componentRef, setIsOpen)
  )

  const toggleVisible = (): void => {
    setIsOpen(!isOpen)
  }

  const classes = 'profile ' + (isOpen ? 'd_menu_show' : '')
  return (
    <li className={classes} ref={componentRef as any}>
      <button type="button" onClick={toggleVisible}>
        <span>{user.email}</span>
      </button>
      <ul>
        <li>
          <Link to={ROUTES.merchants}>
            <IconShoppingCart stroke={1.6} />
            <span>{intl.get('menu.my_merchants')}</span>
          </Link>
        </li>
        <li>
          <a href="#" onClick={onLogoutClick}>
            <IconLogout stroke={1.6} />
            <span>{intl.get('menu.logout')}</span>
          </a>
        </li>
      </ul>
    </li>
  )
}

export default ProfileDropDown
