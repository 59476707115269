import type * as types from 'shared/types'
import { createStore } from 'effector'
import { appDomain } from 'shared/model'
import { api } from 'shared/api'

export const $operations = createStore<types.IMerchantOperation[]>([])
export const loadOperationsFx = appDomain.createEffect(
  api.merchant.getOperations
)

export interface UseOperationsProps {
  isLoading: boolean
  total: number
  perPage: number
  page: number
  operations: types.IMerchantOperation[]
}

$operations.on(loadOperationsFx.doneData, (_, response) => response.data)
