import React, { type FC, useState } from 'react'
import { type IMerchant, type Outcome, OutcomeStatus } from 'shared/types'
import { string, uri } from 'shared/lib'
import intl from 'react-intl-universal'
import { ConfirmDialog, NetworkWithIcon } from 'shared/ui'
import { IconCheck, IconExclamationMark, IconX } from '@tabler/icons-react'
import { confirmRefundFx, deleteRefundFx, deleteWithdrawFx } from '../model'
import { onOutcomeRefresh } from 'pages/outcome/model'
import { confirmWithdrawFx } from "../../../pages/withdraw/model";

interface OutcomeRowProps {
  outcome: Outcome
  merchant: IMerchant
}
const OutcomeRow: FC<OutcomeRowProps> = ({ outcome, merchant }) => {
  const [showActions, setShowActions] = useState(false)
  const onConfirmClick = async (): Promise<void> => {
    setShowActions(false)
    const isRefund = outcome.type === 'rfnd';
    const confirmed = await ConfirmDialog(intl.get(isRefund ? 'refund.confirm_question' : 'outcome.confirm_question'))
    if (confirmed) {
      isRefund ? await confirmRefundFx(outcome.uuid) : await confirmWithdrawFx(outcome.uuid)
      onOutcomeRefresh()
    }
  }
  const onCancelClick = async (): Promise<void> => {
    setShowActions(false)
    const isRefund = outcome.type === 'rfnd';
    const confirmed = await ConfirmDialog(intl.get(isRefund ? 'refund.cancel_question' : 'outcome.cancel_question'))
    if (confirmed) {
      isRefund ? await deleteRefundFx(outcome.uuid) :  await deleteWithdrawFx(outcome.uuid)
      onOutcomeRefresh()
    }
  }

  const toggleActions = (): void => {
    setShowActions(!showActions)
  }
  return (
    <tr>
      <td>{outcome.created_at}</td>
      <td>
        {outcome.amount} {outcome.token}{' '}
          <NetworkWithIcon networkName={outcome.network} />
        {outcome.fiat_amount > 0 && <small className="o-75 fs-13"> <br />{outcome.fiat_amount} {outcome.fiat_currency} </small>}
      </td>
      <td>
        <a
          className="link" target="_blank" rel="noreferrer"
          href={uri.urlToBlockchainAddr(outcome.network, outcome.from_address)}
        >
          {string.shortenAddress(outcome.from_address)}
        </a>
      </td>
      <td>
        <a className="link" target="_blank" rel="noreferrer"
           href={uri.urlToBlockchainAddr(outcome.network, outcome.to_address)}
        >
          {string.shortenAddress(outcome.to_address)}
        </a>
      </td>
      <td>
        {outcome.fee} {outcome.fee_token}
      </td>
      <td>
        <span className="with_icon">
          {intl.get(`outcome.status.${outcome.status}`)}{' '}
          {outcome.status === OutcomeStatus.New && (
            <a
              href="#"
              className={`dropdown-toggler ${showActions ? 'visible' : ''}`}
              onClick={toggleActions}
            >
              {' '}
              <IconExclamationMark />
              <span className="action_icons">
                <a href="#" className="action" onClick={onConfirmClick}>
                  <IconCheck />
                  {intl.get('common.confirm')}
                </a>
                <a href="#" className="action" onClick={onCancelClick}>
                  <IconX />
                  {intl.get('common.cancel')}
                </a>
              </span>
            </a>
          )}
        </span>
      </td>
      <td>{intl.get(`outcome.type.${outcome.type}`)}</td>
      <td></td>
    </tr>
  )
}

export default OutcomeRow
