import { appDomain } from 'shared/model/domain'
import { persist } from 'effector-storage/local'
import { sample } from 'effector'
import { AppGate } from './init'

export const $locale = appDomain.createStore('en')

persist({
  store: $locale,
  key: 'locale'
})
export const setLocale = appDomain.createEvent<string>('Set locale')
$locale.on(setLocale, (_, l) => l)

export const $theme = appDomain.createStore('light')
persist({
  store: $theme,
  key: 'theme'
})

export const switchTheme = appDomain.createEvent('Switch theme')
$theme.on(switchTheme, (t) => (t === 'light' ? 'dark' : 'light'))

const setThemeFx = appDomain.createEffect((theme: string) => {
  const body = document.getElementsByTagName('body')

  if (body.length > 0) {
    theme === 'light'
      ? body[0].classList.remove('dark')
      : body[0].classList.add('dark')
  }
})

sample({
  clock: [$theme, AppGate.open],
  source: $theme,
  target: setThemeFx
})
