import React from 'react'
import { IntlProvider } from 'react-intl'
import { useUnit } from 'effector-react'
import { $locale } from '../model'
import English from 'lang/en.json'
import Russian from 'lang/ru.json'

// eslint-disable-next-line react/display-name
export const withIntl = (component: () => React.ReactNode) => () => {
  const locale = useUnit($locale)

  let messages = English
  if (locale === 'ru') {
    messages = Russian
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      {component()}
    </IntlProvider>
  )
}
