import React, { type ReactElement } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from 'shared/router'
import { $$user } from 'entities/user'

export const PrivateRoute = ({
  children
}: {
  children: ReactElement
}): ReactElement | null => {
  const { isAuthenticated } = $$user.useAuth()

  const location = useLocation()
  if (!isAuthenticated) {
    return <Navigate to={ROUTES.login} state={{ from: location }} replace />
  }
  return children
}
