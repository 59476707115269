import React, { type FC } from 'react'
import { Link, Outlet } from 'react-router-dom'
import { useGate } from 'effector-react'
import { AnonymousGate } from './model'
import LogoSrc from 'shared/i/logo.svg'
import ThemeToggler from 'features/theme-toggler'
import LangSelector from 'features/lang-selector'

export const AnonymousLayout: FC = () => {
  useGate(AnonymousGate)
  return (
    <div className="unauth main">
      <div>
        <div className="navbar">
          <div>
            <nav>
              <Link className="logo mainlogo" to="/">
                <img src={LogoSrc} alt="MEEG" />
              </Link>
              <ul className="items">
                <LangSelector />
                <li className="theme">
                  <ThemeToggler />
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </div>
  )
}
