import { appDomain } from 'shared/model/domain'
import { createEffect, sample } from 'effector'
import { persist } from 'effector-storage/local'
import { tokenParamName } from 'shared/config'
import { api } from 'shared/api'
import { redirect, redirectToLoginFx, ROUTES } from 'shared/router'
import { not } from 'patronum'
import { onLogout, onLogoutDone, showErrorNotification } from 'shared/model'
import intl from 'react-intl-universal'

export const $user = appDomain.createStore<api.auth.LoggedUser>({
  email: '',
  name: '',
  email_verified_at: null
})

export const $userEmailVerified = $user.map((u) => u.email_verified_at !== null)

export const $token = appDomain.createStore<string>('')

persist({
  store: $token,
  key: tokenParamName
})

export const $isAuthenticated = $token.map(Boolean)
$user.reset(onLogoutDone)
$token.reset(onLogoutDone)

export const $isMeLoaded = appDomain.createStore(false)

const logoutFx = appDomain.createEffect(api.auth.logout)
export const signInFx = appDomain.createEffect(api.auth.login)

export const getMeFx = appDomain.createEffect(api.auth.me)
$token.on(signInFx.doneData, (_, token) => token)
$user.on(getMeFx.doneData, (_, newUser) => newUser)

sample({
  clock: onLogout,
  target: logoutFx
})

sample({
  clock: logoutFx.done,
  target: onLogoutDone
})

$isMeLoaded.on(getMeFx.done, (_) => true).reset(onLogoutDone)

// редирект на главную при успешной авторизации
redirect(signInFx.done, ROUTES.root)

sample({
  clock: $isAuthenticated,
  filter: not($isAuthenticated),
  target: redirectToLoginFx
})

const showVerifyEmailNotificationFx = createEffect(async () => {
  showErrorNotification(intl.get('user.verify_email_notification'))
})

sample({
  clock: $isAuthenticated,
  source: $userEmailVerified,
  filter: (emailVerified, authenticated) => authenticated && !emailVerified,
  target: showVerifyEmailNotificationFx
})
