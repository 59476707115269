import React, { type FC, type ReactNode } from 'react'
import intl from 'react-intl-universal'

export type StatusIconVariant = 'gray' | 'red' | 'blue' | 'green'
interface Props {
  variant?: StatusIconVariant
  children: ReactNode | undefined
  title?: string
}
export const StatusIcon: FC<Props> = ({
  variant = 'gray',
  children,
  title
}) => {
  const titleText = title != null ? intl.get(title) : ''
  return (
    <div className={`status_icon ${variant}`} title={titleText}>
      {children}
    </div>
  )
}
