import { Store } from 'react-notifications-component'
import { appDomain, onUserErrorResponse } from './domain'
import { sample } from 'effector'

export const showSuccessNotification = appDomain.createEvent<string>()
export const showErrorNotification = appDomain.createEvent<string>()

interface NotificationProps {
  title: string
  message: string
  type: 'success' | 'danger' | 'info' | 'default' | 'warning'
}
const showNotificationFx = appDomain.createEffect(
  async ({ title, message, type }: NotificationProps) => {
    Store.addNotification({
      title,
      message,
      type,
      insert: 'top',
      container: 'top-center',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    })
  }
)

sample({
  clock: showSuccessNotification,
  fn: (message) => {
    const props = { type: 'success', message, title: '' }
    return props as NotificationProps
  },
  target: showNotificationFx
})

sample({
  clock: onUserErrorResponse,
  fn: (error) => error.message,
  target: showErrorNotification
})

sample({
  clock: showErrorNotification,
  fn: (message) => {
    const props = { type: 'danger', message, title: '' }
    return props as NotificationProps
  },
  target: showNotificationFx
})
