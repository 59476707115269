import React, { createElement, Suspense, useLayoutEffect, useRef, useState } from 'react'
import SplashScreen from 'widgets/splash-screen'
import { type BrowserHistory } from 'history'
import { browserHistory } from 'shared/router'
import { Router } from 'react-router'

// eslint-disable-next-line react/display-name
export const withRouter = (component: () => React.ReactNode) => () => (
  <BrowserRouter>
    <Suspense fallback={<SplashScreen/>}>
      {component()}
    </Suspense>
  </BrowserRouter>
)

function BrowserRouter (_ref: any): any {
  const { basename, children } = _ref
  const historyRef = useRef<BrowserHistory>()

  if (historyRef.current == null) {
    historyRef.current = browserHistory
  }

  const history = historyRef.current
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  })
  useLayoutEffect(() => history.listen(setState), [history])
  // eslint-disable-next-line react/no-children-prop
  return createElement(Router, {
    basename,
    children,
    location: state.location,
    navigationType: state.action,
    navigator: history
  })
}
