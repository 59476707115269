export const capitalize = (term: string): string => {
  return term.charAt(0).toUpperCase() + term.slice(1)
}

export const textOverflow = (term: string, limit: number = 100): string => {
  if (term.length <= limit) return term
  return `${term.slice(0, limit)}...`
}

export const isEmpty = (str?: string): boolean => {
  return str === undefined || str === null || str.trim().length === 0
}

export const lengthGreaterThan = (str: string, len: number): boolean => {
  return str.trim().length > len
}

export const shortenAddress = (address: string): string => {
  return address.substring(0, 6) + '...' + address.substring(address.length - 4)
}
