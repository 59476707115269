import { type Network, type NetworkFee } from 'shared/types/network'
import { http } from '../http'
import { type Rate } from 'shared/types/rate'

/**
 * Получить список сетей и токенов, поддерживаемых сервисом
 */
export const tokens = async (): Promise<Network[]> => {
  const response = await http.get('directory/tokens')
  return response.data.data
}

/**
 * Список комиссий в разных блокчейн сетях
 */
export const fees = async (): Promise<NetworkFee[]> => {
  const response = await http.get('directory/fees')
  return response.data.data
}

export const rates = async (): Promise<Rate[]> => {
  const response = await http.get('directory/rates')
  return response.data.data
}
