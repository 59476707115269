import React, { type FC } from 'react'
import { useUnit } from 'effector-react'
import {
  $activeMerchantUuid,
  activeMerchantChanged,
  useMerchants
} from 'entities/merchant/model'

export const MerchantSelector: FC = () => {
  const { merchants, isLoading } = useMerchants()
  const activeMerchantUuid = useUnit($activeMerchantUuid)

  const handleChange = (e: any): void => {
    activeMerchantChanged(e.target.value)
  }
  return (
    <select
      className="input"
      defaultValue={activeMerchantUuid}
      disabled={isLoading}
      onChange={handleChange}
    >
      {merchants.map((merchant) => (
        <option key={merchant.uuid} value={merchant.uuid}>
          {merchant.title}
        </option>
      ))}
    </select>
  )
}
