import React, { type FC } from 'react'
import intl from 'react-intl-universal'

interface Props {
  page: number
  total: number
  perPage: number
}
const PaginatorInfo: FC<Props> = ({ page, total, perPage }) => {
  if (page === 0) page = 1
  const from = (page - 1) * perPage + 1
  const to = page * perPage > total ? total : page * perPage

  return (
    <div className="info">
      {intl.get('pagination.info', { from, to, total })}
    </div>
  )
}

export default PaginatorInfo
