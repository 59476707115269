import { api } from 'shared/api'
import { appDomain } from 'shared/model'
import { type NetworkFee } from 'shared/types'
import { createEvent, sample } from 'effector'

export const $fees = appDomain.createStore<NetworkFee[]>([])

export const onFeeFetchRequired = createEvent()
export const fetchFeesFx = appDomain.createEffect(api.directory.fees)
$fees.on(fetchFeesFx.doneData, (_, fees) => fees)

sample({
  clock: onFeeFetchRequired,
  target: fetchFeesFx
})
