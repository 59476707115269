import React, { type FC, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom/client'
import intl from 'react-intl-universal'

interface ConfirmDialogProps {
  message: string
  title: string
}

let confirmDialogResponse: (value: boolean) => void

const rootID = 'alert-dialog'

const ConfirmDialog: FC<ConfirmDialogProps> = ({ message, title }) => {
  const onConfirm = (): void => {
    confirmDialogResponse(true)
    Close()
  }

  const Close = (): void => {
    root.current?.remove()
  }
  const onCancel = (): void => {
    confirmDialogResponse(false)
    Close()
  }

  const root = useRef<HTMLDivElement | null>()

  useEffect(() => {
    root.current = document.getElementById(rootID) as HTMLDivElement
  }, [])

  return (
    <div className="modal">
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <h4>{title}</h4>
          </div>
          <div className="modal-body">{message}</div>
          <div className="modal-footer">
            <button type="button" className="btn btn-main" onClick={onConfirm}>
              {intl.get('common.confirm')}
            </button>
            <button
              type="button"
              className="btn btn-gray close"
              onClick={onCancel}
            >
              {intl.get('common.cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const CreateDialog = (message: string, title: string): void => {
  let div = document.getElementById(rootID)
  if (div == null) {
    div = document.createElement('div')
    div.id = rootID
    document.body.appendChild(div)
  }

  const root = ReactDOM.createRoot(div)
  root.render(<ConfirmDialog message={message} title={title} />)
}

export const Confirm = async (
  message: string,
  title: string = intl.get('common.confirm_dialog.title')
): Promise<boolean> => {
  CreateDialog(message, title)
  return await new Promise<boolean>((resolve) => {
    confirmDialogResponse = resolve
  })
}
