import { useStoreMap } from 'effector-react'
import { $tokens } from './index'
import { type Network } from 'shared/types/network'

export const useCryptoTokens = (): Network[] =>
  useStoreMap($tokens, (networks) =>
    networks.filter((network) => !network.is_fiat)
  )
export const useFiatTokens = (): Network | null =>
  useStoreMap(
    $tokens,
    (networks) => networks.find((network) => network.is_fiat) ?? null
  )

export const useNetwork = (networkName: string): Network | null =>
  useStoreMap({
    store: $tokens,
    keys: [networkName],
    fn: (tokens, [networkName]) => {
      return tokens.find(({ name }) => name === networkName) ?? null
    }
  })
