import { appDomain } from 'shared/model/domain'
import { type Wallet } from 'shared/types/wallet'
import { type Uuid } from 'shared/types/common'
import { api } from 'shared/api'
import { sample } from 'effector'

export const $wallets = appDomain.createStore<Wallet[]>([])
export const onWalletsLoadRequired = appDomain.createEvent<Uuid>()
export const loadWalletsFx = appDomain.createEffect(api.wallet.all)
$wallets.on(loadWalletsFx.doneData, (_, data) => data)

sample({
  clock: onWalletsLoadRequired,
  target: loadWalletsFx
})

export const $walletsLoaded = appDomain.createStore(false)
$walletsLoaded.on(loadWalletsFx.doneData, () => true)

export const $walletsLoading = loadWalletsFx.pending
