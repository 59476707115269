import React from 'react'
import { useUnit } from 'effector-react'
import { $locale } from '../model'
import English from 'lang/en.json'
import Russian from 'lang/ru.json'

import intl from 'react-intl-universal'
import useForceUpdate from 'use-force-update'

export const withUIntl = (component: () => React.ReactNode) => () => {
  const locale = useUnit($locale)
  const forceUpdate = useForceUpdate()

  React.useEffect(() => {
    setCurrentLocale(locale)
  }, [locale])

  const LOCALE_DATA = {
    en: English,
    ru: Russian
  }

  const setCurrentLocale = (currentLocale: string): void => {
    if (currentLocale !== 'en' && currentLocale !== 'ru') {
      currentLocale = 'en'
    }
    void intl.init({
      currentLocale,
      locales: LOCALE_DATA
    })
    forceUpdate()
  }

  return component()
}
