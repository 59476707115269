import React, { type FC } from 'react'
import { Content, PageHeader } from 'widgets/chunks'
import { Loader, Paginator, PaginatorInfo, Placeholder } from 'shared/ui'
import { useEvent, useGate, useUnit } from 'effector-react'
import { onPageClicked, useWebhooks, WebhookListGate } from './model'

import { $$merchant } from 'entities/merchant'
import intl from 'react-intl-universal'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import WebhookRow from '../../entities/webhook/ui/webhook-row'

const WebhookList: FC = () => {
  useGate(WebhookListGate)
  const { isLoading, webhooks, perPage, page, total } = useWebhooks()
  const onPageChanged = useEvent(onPageClicked)

  const merchant = useUnit($$merchant.$activeMerchant)
  if (merchant === undefined) return null
  return (
    <>
      <PageHeader title="menu.webhooks" />
      <Content>
        {isLoading && (
          <Placeholder>
            <Loader />
          </Placeholder>
        )}
        {!isLoading && webhooks.length === 0 && (
          <Placeholder>{intl.get('common.no_data')}</Placeholder>
        )}
        {!isLoading && webhooks.length > 0 && (
          <div className="data-tbl">
            <table>
              <thead>
                <tr>
                  <th>
                    <a href="#" className="sort">
                      {intl.get('common.created_at')}
                    </a>
                  </th>
                  <th>{intl.get('webhook.webhook_url')}</th>
                  <th>{intl.get('webhook.attempt')}</th>
                  <th>{intl.get('webhook.status_code')}</th>
                  <td></td>
                </tr>
              </thead>
              <TransitionGroup component="tbody">
                {webhooks.map((webhook) => (
                  <CSSTransition
                    key={webhook.uuid}
                    timeout={500}
                    classNames="tr"
                  >
                    <WebhookRow webhook={webhook} />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </table>

            <div className="data-footer">
              <PaginatorInfo page={page} total={total} perPage={perPage} />
              <Paginator
                total={total}
                current={page}
                pageSize={perPage}
                onPageChange={onPageChanged}
              />
            </div>
          </div>
        )}
      </Content>
    </>
  )
}

export default WebhookList
