import { sample } from 'effector'
import {
  $outcomePaginatorPage,
  $outcomePaginatorPageSize,
  $outcomePaginatorTotal,
  $outcomes,
  loadOutcomesListFx,
  onOutcomeRefresh,
  onPageClicked,
  onPageSizeChanged,
  OutcomeListGate
} from './store'
import { $$merchant } from 'entities/merchant'

sample({
  clock: [
    OutcomeListGate.open,
    $outcomePaginatorPage,
    $outcomePaginatorPageSize,
    onOutcomeRefresh
  ],
  source: {
    page: $outcomePaginatorPage,
    perPage: $outcomePaginatorPageSize,
    uuid: $$merchant.$activeMerchantUuid
  },
  filter: ({ uuid }) => Boolean(uuid),
  fn: ({ page, perPage, uuid }) => ({
    merchantUuid: uuid ?? '',
    page,
    perPage
  }),
  target: loadOutcomesListFx
})

$outcomePaginatorTotal.on(
  loadOutcomesListFx.doneData,
  (_, data) => data.meta.total
)

$outcomePaginatorPageSize.on(onPageSizeChanged, (_, pageSize) => pageSize)
$outcomePaginatorPage.on(onPageClicked, (_, pageNum) => pageNum)

$outcomes.on(loadOutcomesListFx.doneData, (_, response) => response.data)
