import React, { type FC, lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ROUTES } from 'shared/router'
import { AnonymousLayout, AuthenticatedLayout } from 'pages/layouts'
import { PrivateRoute } from 'app/private-route'
import OutcomesList from '../pages/outcome'
import WebhookList from '../pages/webhook'

const MainPage = lazy(async () => await import('pages/main-page'))
const WalletsPage = lazy(async () => await import('pages/wallets'))
const InvoiceList = lazy(async () => await import('pages/invoice-list'))
const OperationList = lazy(async () => await import('pages/operation-list'))
const MerchantList = lazy(async () => await import('pages/merchant/list'))
const MerchantCreate = lazy(async () => await import('pages/merchant/create'))
const MerchantEdit = lazy(async () => await import('pages/merchant/edit'))
const LoginPage = lazy(async () => await import('pages/login'))
const RegisterPage = lazy(async () => await import('pages/register'))
const ConfirmEmail = lazy(async () => await import('pages/confirm'))
const ForgotPassword = lazy(async () => await import('pages/forgot-password'))
const ResetPassword = lazy(async () => await import('pages/reset-password'))
const AccessDenied = lazy(async () => await import('pages/error/access-denied'))
const PageNotFound = lazy(async () => await import('pages/error/not-found'))
const InvoiceDetails = lazy(async () => await import('pages/invoice-details'))

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<AnonymousLayout />}>
        <Route path={ROUTES.login} element={<LoginPage />} />
        <Route path={ROUTES.registration} element={<RegisterPage />} />
        <Route path={ROUTES.confirm_email} element={<ConfirmEmail />} />
        <Route path={ROUTES.forgot_password} element={<ForgotPassword />} />
        <Route path={ROUTES.reset_password} element={<ResetPassword />} />
        <Route path="*" element={<Navigate to={ROUTES.login} replace />} />
      </Route>
      <Route element={<AuthenticatedLayout />}>
        <Route
          path={ROUTES.root}
          element={
            <PrivateRoute>
              <MainPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.wallets}
          element={
            <PrivateRoute>
              <WalletsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.invoices}
          element={
            <PrivateRoute>
              <InvoiceList />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.invoice_detail}
          element={
            <PrivateRoute>
              <InvoiceDetails />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.operations}
          element={
            <PrivateRoute>
              <OperationList />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.merchants}
          element={
            <PrivateRoute>
              <MerchantList />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.merchant_create}
          element={
            <PrivateRoute>
              <MerchantCreate />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.merchant_view}
          element={
            <PrivateRoute>
              <MerchantEdit />
            </PrivateRoute>
          }
        />

        <Route
          path={ROUTES.outcomes}
          element={
            <PrivateRoute>
              <OutcomesList />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.webhooks}
          element={
            <PrivateRoute>
              <WebhookList />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.access_denied}
          element={
            <PrivateRoute>
              <AccessDenied />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.page_not_found}
          element={
            <PrivateRoute>
              <PageNotFound />
            </PrivateRoute>
          }
        />
      </Route>
    </Routes>
  )
}

export default AppRoutes
