import { type Rule } from 'effector-forms'
import { email, uri } from './index'

export const rules = {
  required: (): Rule<string | number | null> => ({
    name: 'required',
    validator: (value) => Boolean(value)
  }),
  email: (): Rule<string> => ({
    name: 'email',
    validator: email.isValid
  }),
  minLength: (min: number): Rule<string> => ({
    name: 'minLength',
    validator: (value) => value.length >= min
  }),
  maxLength: (max: number): Rule<string> => ({
    name: 'maxLength',
    validator: (value) => value.length <= max
  }),
  url: (): Rule<string> => ({
    name: 'url',
    validator: uri.isValid
  })
}
