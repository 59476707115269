import { sample } from 'effector'
import {
  $activeMerchantUuid,
  $activeMerchantUuidIsInMerchantsList,
  $merchants,
  $merchantsLoaded,
  activeMerchantChanged,
  closeMerchantFx,
  createMerchantFx,
  loadMerchantFx,
  loadMerchantsFx,
  onMerchantClose,
  updateMerchantFx
} from './store'
import { redirect, ROUTES } from 'shared/router'
import { onLogout } from 'shared/model'
import { not } from 'patronum'
import { MerchantStatus } from 'shared/types'

$merchants.on(loadMerchantsFx.doneData, (_, merchants) => merchants)
$merchants.on(loadMerchantFx.doneData, (store, merchant) => {
  const filtered = store.filter((m) => m.uuid !== merchant.uuid)
  return [...filtered, merchant]
})
$activeMerchantUuid.on(
  activeMerchantChanged,
  (_, newMerchantUuid) => newMerchantUuid
)

$merchantsLoaded.on(loadMerchantsFx.done, () => true)
$merchantsLoaded.reset(onLogout)

sample({
  clock: [
    createMerchantFx.doneData,
    closeMerchantFx.doneData,
    updateMerchantFx.doneData
  ],
  target: loadMerchantsFx
})

sample({
  clock: onMerchantClose,
  target: closeMerchantFx
})

const onMerchantSwitchRequired = sample({
  clock: loadMerchantsFx.done,
  filter: not($activeMerchantUuidIsInMerchantsList)
})

sample({
  clock: onMerchantSwitchRequired,
  source: $merchants,
  fn: (merchants) => {
    const length = merchants.length
    if (length < 1) return ''
    const active = merchants.find((m) => m.status === MerchantStatus.Active)
    if (active !== undefined) return active.uuid
    return merchants[0].uuid
  },
  target: activeMerchantChanged
})

redirect(createMerchantFx.doneData, ROUTES.merchants)
// redirect(activeMerchantChanged, ROUTES.root)
