import { useUnit } from 'effector-react'
import {
  $webhookPaginatorPage,
  $webhookPaginatorPageSize,
  $webhookPaginatorTotal,
  $webhooks,
  loadWebhookListFx
} from './store'
import { type UseWebhooksProps } from 'entities/webhook/model'

export const useWebhooks = (): UseWebhooksProps => {
  const isLoading = useUnit(loadWebhookListFx.pending)
  const webhooks = useUnit($webhooks)

  const total = useUnit($webhookPaginatorTotal)
  const perPage = useUnit($webhookPaginatorPageSize)
  const page = useUnit($webhookPaginatorPage)

  return { webhooks, isLoading, total, perPage, page }
}
