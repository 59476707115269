import { useStoreMap, useUnit } from 'effector-react'
import { $merchants, loadMerchantsFx } from './index'
import type * as types from 'shared/types'

interface UseMerchantsProps {
  isLoading: boolean
  merchants: types.IMerchant[]
}
export const useMerchants = (): UseMerchantsProps => {
  const merchants = useUnit($merchants)
  const isLoading = useUnit(loadMerchantsFx.pending)

  return { merchants, isLoading }
}

export const useMerchant = (merchantUuid: types.Uuid): types.IMerchant | null =>
  useStoreMap({
    store: $merchants,
    keys: [merchantUuid],
    fn: (merchants, [merchantUuid]) => {
      return merchants.find(({ uuid }) => uuid === merchantUuid) ?? null
    }
  })
