import React, { type FC } from 'react'
import { type IconColorProps } from './IconColorProps'

const Alert: FC<IconColorProps> = ({ color = '#4B465C', width = 25 }) => {
  return (
    <svg
      width={width}
      height={(width * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 8V11.7816"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 8V11.7816"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.5388L12.5 15.5863"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15.5388L12.5 15.5863"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49995 19H19.5C20.1625 18.9953 20.7797 18.6629 21.1482 18.1122C21.5166 17.5616 21.5884 16.8642 21.34 16.25L14.24 3.99999C13.8877 3.36335 13.2175 2.96823 12.49 2.96823C11.7624 2.96823 11.0922 3.36335 10.74 3.99999L3.63995 16.25C3.39635 16.8497 3.45807 17.5303 3.8056 18.0764C4.15313 18.6225 4.74349 18.9667 5.38995 19"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.49995 19H19.5C20.1625 18.9953 20.7797 18.6629 21.1482 18.1122C21.5166 17.5616 21.5884 16.8642 21.34 16.25L14.24 3.99999C13.8877 3.36335 13.2175 2.96823 12.49 2.96823C11.7624 2.96823 11.0922 3.36335 10.74 3.99999L3.63995 16.25C3.39635 16.8497 3.45807 17.5303 3.8056 18.0764C4.15313 18.6225 4.74349 18.9667 5.38995 19"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Alert
