import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  HttpStatusCode,
  type InternalAxiosRequestConfig
} from 'axios'

import { API_URL, tokenParamName } from 'shared/config'
import { storage } from '../lib'
import {
  AuthenticationError,
  DomainError,
  ForbiddenError,
  NetworkError,
  PageNotFoundError
} from './errors'

interface APIResponse {
  message?: string
  code?: number
  data?: unknown
}

const headers: Readonly<Record<string, string | boolean>> = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=utf-8'
}

const injectToken = (
  config: InternalAxiosRequestConfig
): InternalAxiosRequestConfig => {
  try {
    const token: string = storage.fromLocalStorage(tokenParamName)

    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`
    }
  } catch (error) {
    console.log(error)
    throw error
  }
  return config
}

class Http {
  private instance: AxiosInstance | null = null

  private get http(): AxiosInstance {
    return this.instance ?? this.initHttp()
  }

  initHttp(): AxiosInstance {
    const http = axios.create({
      baseURL: API_URL,
      headers
    })

    http.interceptors.request.use(
      injectToken,
      async (error) => await Promise.reject(error)
    )

    http.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        return await this.handleError(error)
      }
    )

    this.instance = http
    return http
  }

  async get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.get<T, R>(url, config)
  }

  async post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.post<T, R>(url, data, config)
  }

  async patch<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.patch<T, R>(url, data, config)
  }

  async delete<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return await this.http.delete<T, R>(url, config)
  }

  // Handle global app errors
  private async handleError(error: AxiosError): Promise<Error> {
    if (error.response == null) {
      if (error.code === 'ERR_NETWORK') {
        throw new NetworkError('Network error')
      }
      throw new Error(error.message)
    }

    const status = error.response.status as HttpStatusCode
    switch (status) {
      case HttpStatusCode.BadRequest: {
        const response = error.response.data as APIResponse
        throw new DomainError(response.code, response.message)
      }
      case HttpStatusCode.InternalServerError:
        throw new Error('Server Error')
      case HttpStatusCode.NotFound:
        throw new PageNotFoundError('Page not found')
      case HttpStatusCode.Forbidden:
        throw new ForbiddenError('Unauthorized')
      case HttpStatusCode.Unauthorized:
        throw new AuthenticationError('Unauthorized')
      case HttpStatusCode.TooManyRequests:
        throw new Error('Slow down')
      default:
        throw new Error(error.message)
    }
  }
}

export const http = new Http()
