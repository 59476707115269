import React, { type FC } from 'react'
import intl from 'react-intl-universal'
import { IconPlus } from '@tabler/icons-react'
import { Link } from 'react-router-dom'

interface ButtonProps {
  onClick?: () => void
  to: string
  titleId: string
}
const AddButton: FC<ButtonProps> = (props) => {
  return (
    <Link
      className="btn btn-main with_icon"
      to={props.to}
      onClick={props.onClick}
    >
      <IconPlus stroke={2} size={18} /> {intl.get(props.titleId)}
    </Link>
  )
}

export default AddButton
