import React, { type FC } from 'react'
import * as placeholders from './elements'

interface PlaceholderProps {
  children?: React.ReactNode
  /** pass `true` when the content is ready and `false` when it's loading */
  ready: boolean
  className?: string
  rows?: number
}

type ButtonPlaceholderProps = PlaceholderProps & {
  type: 'button'
}

type TextPlaceholderProps = PlaceholderProps & {
  type: 'text'
}

export type Props = ButtonPlaceholderProps | TextPlaceholderProps

export const UiPlaceholder: FC<Props> = ({
  type = 'text',
  rows = 1,
  ready,
  children,
  className
}) => {
  if (ready) return children as React.ReactElement
  const ExactPlaceholder = placeholders[type]
  return (
    <div className={className}>
      {Array.apply(null, Array(rows)).map((_, i) => (
        <ExactPlaceholder key={i} />
      ))}
    </div>
  )
}
