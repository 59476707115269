import { sample } from 'effector'
import {
  $webhookPaginatorPage,
  $webhookPaginatorPageSize,
  $webhookPaginatorTotal,
  $webhooks,
  loadWebhookListFx,
  onPageClicked,
  onPageSizeChanged,
  WebhookListGate
} from './store'
import { $$merchant } from 'entities/merchant'

sample({
  clock: [
    WebhookListGate.open,
    $webhookPaginatorPage,
    $webhookPaginatorPageSize
  ],
  source: {
    page: $webhookPaginatorPage,
    perPage: $webhookPaginatorPageSize,
    uuid: $$merchant.$activeMerchantUuid
  },
  filter: ({ uuid }) => Boolean(uuid),
  fn: ({ page, perPage, uuid }) => ({
    merchantUuid: uuid ?? '',
    page,
    perPage
  }),
  target: loadWebhookListFx
})

$webhookPaginatorTotal.on(
  loadWebhookListFx.doneData,
  (_, data) => data.meta.total
)

$webhookPaginatorPageSize.on(onPageSizeChanged, (_, pageSize) => pageSize)
$webhookPaginatorPage.on(onPageClicked, (_, pageNum) => pageNum)

$webhooks.on(loadWebhookListFx.doneData, (_, response) => response.data)
