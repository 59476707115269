import { createGate } from 'effector-react'
import { $$merchant } from 'entities/merchant'
import { $$token } from 'entities/token'
import { $$user } from 'entities/user'
import { and, every, not, some } from 'patronum'
import { appDomain } from 'shared/model'
import { createEffect } from 'effector/compat'
import { sample } from 'effector'

export const AuthenticatedGate = createGate('Authenticated gate')
export const AnonymousGate = createGate('Anonymous gate')

export const $isAppInitialized = and(
  $$merchant.$merchantsLoaded,
  $$token.$tokenListLoaded,
  $$user.$isMeLoaded
)

const $initialEffectsPending = some({
  predicate: true,
  stores: [
    $$merchant.loadMerchantsFx.pending,
    $$user.getMeFx.pending,
    $$token.loadTokensFx.pending
  ]
})

export const $appInitInProgress = every({
  predicate: true,
  stores: [not($isAppInitialized), $initialEffectsPending]
})

export const onToggleMenuClick = appDomain.createEvent()
export const onShortenMenuClick = appDomain.createEvent()

const shortenMenuFx = createEffect(async () => {
  const body = document.getElementsByTagName('body')[0]
  body.classList.toggle('menu-short')
  body.classList.remove('menu-show')
})

const toggleMenuFx = createEffect(async () => {
  document.getElementsByTagName('body')[0].classList.toggle('menu-show')
})

sample({
  clock: onShortenMenuClick,
  target: shortenMenuFx
})

sample({
  clock: onToggleMenuClick,
  target: toggleMenuFx
})
