import React, { type FC } from 'react'
import { useNetwork } from 'entities/token/model'

interface Props {
  networkName: string
}
const NetworkWithIcon: FC<Props> = ({ networkName }) => {
  const network = useNetwork(networkName)
  const mainToken = network?.tokens.find((t) => t.is_main)
  return (
    <span className="token_with_logo">
      {mainToken != null && (
        <img src={mainToken.logo} title={network?.description} />
      )}
      {networkName}
    </span>
  )
}

export default NetworkWithIcon
