import { type Url, type Uuid } from './common'

export enum MerchantStatus {
  New = 0,
  Pending = 1,
  Active = 2,
  Rejected = 3,
  Closed = 4,
  Suspended = 5,
  Blocked = 6
}

export enum MerchantPermission {
  InvoicesView = 'invoices_view',
  InvoicesEdit = 'invoices_edit',
  OperationsView = 'operations_view',
  Refund = 'refund',
  Withdraw = 'withdraw',
  EditSettings = 'settings_edit',
  AmlDecisions = 'aml_decisions',
  Webhooks = 'webhooks',
  Any = 'any'
}

export interface IMerchant {
  readonly uuid: Uuid
  title: string
  website: Url
  success_url: Url
  failed_url: Url
  webhook_url: Url
  readonly status: MerchantStatus
  allowed_tokens: number[]
  permissions: MerchantPermission[]
}

export enum MerchantOperationType {
  Income = 'income',
  AmlFeeAcc = 'aml_fee_acc',
  FeeAcc = 'fee_acc',
  FeeOut = 'fee_out',
  Withdrawal = 'withdrawal',
  Refund = 'refund',
  FeeIncome = 'fee_income',
  WithdrawalFee = 'withdrawal_fee',
  WithdrawalFeeAccrual = 'withdrawal_fee_acc',
  NetworkFeeAccrual = 'network_fee_acc'
}

export interface IMerchantOperation {
  initial_amount: number
  initial_token: string
  initial_network: string
  amount: number
  token: string
  network: string
  type: MerchantOperationType
  comment: string
  created_at: string
}
