export const ROUTES = {
  root: '/',
  login: '/login',
  registration: '/registration',
  confirm_email: '/email-confirm',
  forgot_password: '/forgot-password',
  reset_password: '/reset-password',
  profile: '/profile',
  settings: '/settings',
  invoices: '/invoices',
  invoice_detail: '/invoices/:uuid',
  invoice_create: '/invoices/create',
  merchants: '/merchants',
  merchant_create: '/merchants/create',
  merchant_view: '/merchants/:uuid',
  wallets: '/wallets',
  operations: '/operations',
  outcomes: '/outcomes',
  webhooks: '/webhooks',
  access_denied: '/denied',
  page_not_found: '/404'
} as const
