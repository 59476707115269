import React, { type FC } from 'react'
import Sidebar from 'widgets/sidebar'
import TopBar from 'widgets/topbar'
import { Outlet } from 'react-router-dom'
import { useGate, useUnit } from 'effector-react'
import { $appInitInProgress, AuthenticatedGate } from './model'
import SplashScreen from 'widgets/splash-screen'

export const AuthenticatedLayout: FC = () => {
  useGate(AuthenticatedGate)
  const isAppInInitPhase = useUnit($appInitInProgress)
  if (isAppInInitPhase) return <SplashScreen />

  return (
    <>
      <Sidebar />
      <main id="main" className="main">
        <TopBar />
        <div className="tpl_content">
          <Outlet />
        </div>
      </main>
    </>
  )
}
