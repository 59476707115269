import type * as types from 'shared/types'
import { http } from '../http'
import { HttpStatusCode } from 'axios'

export interface GetWebhookParams {
  merchantUuid: types.Uuid
  page?: number
  perPage?: number
}
export const getWebhooks = async ({
  merchantUuid,
  page,
  perPage
}: GetWebhookParams): Promise<types.IPaginatedResponse<types.Webhook>> => {
  const params = { page, per_page: perPage }
  const response = await http.get(`/merchants/${merchantUuid}/webhooks`, {
    params
  })
  return response.data.data
}

export const resendWebhook = async (
  webhookUuid: types.Uuid
): Promise<boolean> => {
  const response = await http.get(`webhooks/${webhookUuid}/resend`)
  return response.data.status === HttpStatusCode.Accepted
}
