import { type Uuid } from './common'

export enum OutcomeStatus {
  New = 0,
  Confirmed = 1,
  InProgress = 2,
  Expired = 6,
  Error = 7,
  Successful = 9
}

export type OutcomeType = 'wtd' | 'rfnd' | 'feewtd'

export interface Outcome {
  readonly uuid: Uuid
  from_address: string
  to_address: string
  token: string
  network: string
  amount: number
  readonly fee: number
  readonly fee_token: string
  readonly status: OutcomeStatus
  readonly created_at: string
  readonly type: OutcomeType
  readonly paid_by_service_fee: number
  readonly paid_by_service_in_token: number
  readonly paid_by_user_fee: number
  readonly fiat_currency: string
  readonly fiat_amount: number
  readonly memo: string | null
}
