import React, { type FC } from 'react'
import ThemeToggler from 'features/theme-toggler'
import LangSelector from 'features/lang-selector'
import ProfileDropDown from 'features/profile-dropdown'
import { IconBell, IconMenu2 } from '@tabler/icons-react'
import { onToggleMenuClick } from 'pages/layouts/model'

const TopBar: FC = () => {
  return (
    <div className="navbar">
      <div>
        <nav>
          <div>
            <button
              type="button"
              className="btn_menu"
              onClick={() => {
                onToggleMenuClick()
              }}
            >
              <IconMenu2 />
            </button>
          </div>
          <ul className="items">
            <LangSelector />
            <li className="theme">
              <ThemeToggler />
            </li>
            <li className="notifications">
              <a href="#">
                <IconBell />
                {/* <div>0</div> */}
              </a>
            </li>
            <ProfileDropDown />
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default TopBar
