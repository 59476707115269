import React, { type FC, type ReactNode } from 'react';

interface Props {
  children?: ReactNode
}
const Placeholder: FC<Props> = ({children}) => {
  return (
  <div className="placeholder">
    {children ?? ''}
  </div>
  );
};

export default Placeholder;