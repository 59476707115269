import { appDomain } from 'shared/model'
import { type Webhook } from 'shared/types'
import { attach, createEvent, createStore } from 'effector'
import { createGate } from 'effector-react'
import { loadWebhooksFx } from 'entities/webhook/model'

export const $webhooks = appDomain.createStore<Webhook[]>([])
export const WebhookListGate = createGate('Webhook List Gate')
export const onPageClicked = createEvent<number>()
export const onPageSizeChanged = createEvent<number>()
export const $webhookPaginatorPage = createStore(0)
export const $webhookPaginatorPageSize = createStore(10)
export const $webhookPaginatorTotal = createStore(0)
export const loadWebhookListFx = attach({
  effect: loadWebhooksFx
})
