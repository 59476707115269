import { type RefObject } from 'react'

export function listenForOutsideClicks(
  listening: boolean,
  setListening: (l: boolean) => void,
  menuRef: RefObject<HTMLElement>,
  setIsOpen: (opened: boolean) => void
) {
  return () => {
    if (listening) return
    if (menuRef.current == null) return
    setListening(true)
    ;[`click`, `touchstart`].forEach((type) => {
      document.addEventListener(`click`, (evt) => {
        const cur = menuRef.current
        const node = evt.target as Node
        if (cur?.contains(node) === true) return
        setIsOpen(false)
      })
    })
  }
}
