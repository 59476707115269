import React, { type FC } from 'react'
import { $theme, switchTheme } from 'app/model'
import { useUnit } from 'effector-react'
import { IconMoon, IconSun } from '@tabler/icons-react'

const ThemeToggler: FC = () => {
  const onThemeToggle = (): void => {
    switchTheme()
  }

  const theme = useUnit($theme)
  return (
    <div onClick={onThemeToggle}>
      {theme === 'dark' ? <IconSun /> : <IconMoon />}
    </div>
  )
}

export default ThemeToggler
