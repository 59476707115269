import { createDomain } from 'effector'

export const appDomain = createDomain('app')
export const onAppError = appDomain.createEvent<Error>('On App Error')

export const onUnauthenticatedResponse = appDomain.createEvent(
  'Unauthenticated response received'
)

export const onAccessDeniedResponse = appDomain.createEvent(
  'Access denied response received'
)

export const onPageNotFoundResponse = appDomain.createEvent(
  'Page not found response received'
)

export const onUserErrorResponse = appDomain.createEvent<Error>(
  'User notification response'
)

export const onNetworkErrorResponse = appDomain.createEvent<Error>(
  'Network error response'
)

export const OnLoginSuccess = appDomain.createEvent()
export const onLogout = appDomain.createEvent()
export const onLogoutDone = appDomain.createEvent()
