/**
 * Модуль инициализации env-переменных
 * @remark Если не найдено значение хоть одной переменной,
 * Приложение сразу выбросит ошибку, при инициализации модуля
 * @module
 */

/**
 * Получение env-переменной
 * @throwable
 */
const getEnvVar = (key: string): any => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`)
  }
  return process.env[key] ?? ''
}

/** API entrypoint */
export const API_URL = getEnvVar('REACT_APP_API_URL')
export const INVOICE_REMOTE_URL = getEnvVar('REACT_APP_REMOTE_INVOICE_URL')

/** Режим запуска программы */
export const NODE_ENV = getEnvVar('NODE_ENV')
/** Режим разработки */
export const isDevEnv = NODE_ENV === 'development'
/** Режим продакшена */
export const isProdEnv = NODE_ENV === 'production'

export const tokenParamName = 'access_token'

export const blockchainViewers = {
  BSC: {
    transaction: 'https://bscscan.com/tx/{txid}',
    address: 'https://bscscan.com/address/{address}'
  },
  BTC: {
    transaction: 'https://btcscan.org/tx/{txid}',
    address: 'https://btcscan.org/address/{address}'
  },
  ETH: {
    transaction: 'https://etherscan.io/tx/{txid}',
    address: 'https://etherscan.io/address/{address}'
  },
  TRON: {
    transaction: 'https://tronscan.io/#/transaction/{txid}',
    address: 'https://tronscan.io/#/address/{address}'
  },
  LTC: {
    transaction: 'https://litecoinblockexplorer.net/tx/{txid}',
    address: 'https://litecoinblockexplorer.net/address/{address}'
  },
  DASH: {
    transaction: 'https://dashblockexplorer.com/tx/{txid}',
    address: 'https://dashblockexplorer.com/address/{address}'
  },
  TON: {
    transaction: 'https://tonscan.org/tx/{txid}',
    address: 'https://tonscan.org/address/{address}'
  }
}
