import React, { type FC, useState } from 'react'
import type * as types from 'shared/types'
import { IconRefresh, IconRotateClockwise2 } from '@tabler/icons-react'
import { $resendInProgress, resendWebhook } from '../model'
import { useUnit } from 'effector-react'
import { ConfirmDialog } from 'shared/ui'
import intl from 'react-intl-universal'

interface Props {
  webhook: types.Webhook
}
const WebhookRow: FC<Props> = ({ webhook }) => {
  const [clicked, setClicked] = useState(true)
  const resendInProgress = useUnit($resendInProgress)
  const handleResendClick = async (): Promise<void> => {
    const confirmed = await ConfirmDialog(intl.get('webhook.confirm_resend'))
    if (confirmed) {
      setClicked(true)
      resendWebhook(webhook.uuid)
    }
  }
  return (
    <tr>
      <td>{webhook.created_at}</td>
      <td title={JSON.stringify(webhook.payload)}>{webhook.webhook_url}</td>
      <td>{webhook.attempt}</td>
      <td>{webhook.status_code}</td>
      <td>
        {(!resendInProgress || !clicked) && (
          <a href="#" onClick={handleResendClick}>
            <IconRefresh stroke={1.6} />
          </a>
        )}
        {resendInProgress && clicked && <IconRotateClockwise2 />}
      </td>
    </tr>
  )
}

export default WebhookRow
