import React, { useEffect, useRef, useState } from 'react'
import UsFlagSrc from 'shared/i/flags/us.svg'
import RuFlagSrc from 'shared/i/flags/ru.svg'
import { $locale, setLocale } from 'app/model'
import { useUnit } from 'effector-react'
import { listenForOutsideClicks } from 'shared/lib'

const LangSelector: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [listening, setListening] = useState(false)

  const componentRef = useRef<HTMLElement>(null)
  const toggleVisible = (): void => {
    setIsOpen(!isOpen)
  }

  useEffect(
    listenForOutsideClicks(listening, setListening, componentRef, setIsOpen)
  )

  const currentLang = useUnit($locale)

  const currentFlag = currentLang === 'ru' ? RuFlagSrc : UsFlagSrc

  const setLang = (lang: string): void => {
    setLocale(lang)
    setIsOpen(false)
  }

  const classes = 'lng ' + (isOpen ? 'd_menu_show' : '')
  return (
    <li className={classes} ref={componentRef as any}>
      <img src={currentFlag} alt={currentLang} onClick={toggleVisible} />
      <ul>
        <li>
          <a
            onClick={() => {
              setLang('en')
            }}
          >
            <img src={UsFlagSrc} alt="English" />
            &nbsp;English
          </a>
        </li>
        <li>
          <a
            onClick={() => {
              setLang('ru')
            }}
          >
            <img src={RuFlagSrc} alt="Русский" />
            &nbsp;Русский
          </a>
        </li>
      </ul>
    </li>
  )
}

export default LangSelector
