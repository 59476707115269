import { http } from 'shared/api/http'

import { type AxiosRequestConfig, type AxiosResponse } from 'axios'
import type * as types from 'shared/types'

const BASE_URL = 'merchants'

export interface GetInvoicesParams {
  merchantUuid: types.Uuid
  page?: number
  perPage?: number,
  filters: types.Filter[]
}

interface CreateInvoiceParams {
  amount: number
  currency: string
  network?: string
  order_num?: string
  products: types.InvoiceProduct[]
}

/**
 * Получить список счетов для торговой точки
 * @param merchantUuid
 * @param page
 * @param per_page
 */
export const getInvoices = async ({
  merchantUuid,
  page,
  perPage,
  filters
}: GetInvoicesParams): Promise<types.IPaginatedResponse<types.Invoice>> => {
  const filtersQs = filters.map(filter => `filter[${filter.name}] = ${encodeURIComponent(filter.value)}`).join('&');
  const params = { page, per_page: perPage }
  const response = await http.get(`${BASE_URL}/${merchantUuid}/invoices?${filtersQs}`, {
    params
  })
  return response.data.data
}

/**
 * Добавить новый счет для торговой точки
 */
export const createInvoice = async (args: {
  merchantUuid: types.Uuid
  invoice: CreateInvoiceParams
}): Promise<types.Invoice> => {
  const { merchantUuid, invoice } = args
  const response = await http.post<CreateInvoiceParams, AxiosResponse>(
    `${BASE_URL}/${merchantUuid}/invoices`,
    invoice
  )
  return response.data.data
}

/**
 * Обновить счет на оплату
 */
export const updateInvoice = async (args: {
  merchantUuid: types.Uuid
  invoice: CreateInvoiceParams
}): Promise<types.Invoice> => {
  const { merchantUuid, invoice } = args
  const response = await http.patch<CreateInvoiceParams, AxiosResponse>(
    `invoices/${merchantUuid}`,
    invoice
  )
  return response.data.data
}

/**
 * Получить информацию о счете на оплату по идентификатору
 * @param invoiceUuid
 */
export const getInvoice = async (
  invoiceUuid: types.Uuid
): Promise<types.Invoice> => {
  const response = await http.get(`invoices/${invoiceUuid}`)
  return response.data.data
}

/**
 * Удалить счет на оплату
 * @param invoiceUuid
 */
export const deleteInvoice = async (
  invoiceUuid: types.Uuid
): Promise<types.Uuid> => {
  const response = await http.delete(`invoices/${invoiceUuid}`)
  return response.data.data
}

export const acceptPartiallyPaidInvoice = async (
  invoiceUuid: types.Uuid
): Promise<types.Uuid> => {
  const response = await http.patch(`invoices/${invoiceUuid}/accept`)
  return response.data.data
}

/**
 * Список блокчейн транзакций счета на оплату
 * @param invoiceUuid
 */
export const getInvoiceTransactions = async (
  invoiceUuid: types.Uuid
): Promise<types.Transaction[]> => {
  const response = await http.get(`invoices/${invoiceUuid}/transactions`)
  return response.data.data
}

/**
 * Список финансовых операций для инвойса
 */
export const getInvoiceOperations = async (
  invoiceUuid: types.Uuid
): Promise<types.IMerchantOperation[]> => {
  const response = await http.get(`invoices/${invoiceUuid}/operations`)
  return response.data.data
}

/**
 * AML отчет поступивших по счету на оплату средств
 * @param invoiceUuid
 */
export const getInvoiceAmlReport = async (
  invoiceUuid: types.Uuid
): Promise<types.AmlReport | null> => {
  const response = await http.get(`invoices/${invoiceUuid}/aml`)
  return response.data.data
}

interface InvoiceCurrencyParams {
  invoiceUuid: types.Uuid
  token: string
  network: string
}

/**
 * Установить валюту и сеть выбранные пользователем для оплаты
 */
export const setInvoiceCurrency = async (
  args: InvoiceCurrencyParams
): Promise<types.Invoice> => {
  const { token, network, invoiceUuid } = args
  const params = { token, network }
  const response = await http.patch<AxiosRequestConfig, AxiosResponse>(
    `invoices/${invoiceUuid}/currency`,
    { params }
  )
  return response.data.data
}
