import { type Uuid } from 'shared/types/common'
import { type Wallet } from 'shared/types/wallet'
import { http } from '../http'
import { type AxiosResponse } from 'axios'

/**
 *
 * @param merchantUuid
 */
export const all = async (merchantUuid: Uuid): Promise<Wallet[]> => {
  const response = await http.get(`/merchants/${merchantUuid}/wallets`)
  return response.data.data
}

export interface SetAmlParams {
  aml_action?: string
  aml_threshold?: number
  walletUuid: Uuid
}
export const setAml = async (
  // eslint-disable-next-line @typescript-eslint/naming-convention
  { walletUuid, aml_action, aml_threshold }: SetAmlParams
): Promise<Wallet> => {
  const response = await http.patch<any, AxiosResponse>(
    `/wallets/${walletUuid}`,
    { aml_action, aml_threshold }
  )
  return response.data.data
}
