import { api } from 'shared/api'
import { appDomain } from 'shared/model/domain'
import { type Network } from 'shared/types/network'

export const $tokens = appDomain.createStore<Network[]>([])

export const loadTokensFx = appDomain.createEffect(api.directory.tokens)
$tokens.on(loadTokensFx.doneData, (_, tokens) => tokens)

export const $tokenListLoaded = appDomain.createStore(false)
$tokenListLoaded.on(loadTokensFx.doneData, (_) => true)
