import React, { type FC } from 'react'
import { type IconColorProps } from './IconColorProps'

const CircleCheck: FC<IconColorProps> = ({ color = 'currentColor' }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12.5" cy="12" r="9" stroke={color} strokeWidth="1.5" />
      <circle
        cx="12.5"
        cy="12"
        r="9"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path d="M9.5 12L11.5 14L15.5 10" stroke={color} strokeWidth="1.5" />
      <path
        d="M9.5 12L11.5 14L15.5 10"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default CircleCheck
