import React, { type FC } from 'react'
import { Loader } from 'shared/ui'

const SplashScreen: FC = () => {
  return (
    <div className="splash_screen">
      <Loader />
    </div>
  )
}

export default SplashScreen
