import React, { type FC } from 'react'

interface TokenProps {
  title: string
  logo: string
}
const Token: FC<TokenProps> = ({ logo, title }) => {
  return (
    <div className="token_with_logo">
      <img src={logo} alt="Meeg" />
      {title}
    </div>
  )
}

export default Token
