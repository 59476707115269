import * as React from 'react'

export interface Props {
  maxHeight?: string | number
  lineSpacing?: string | number
}

const TextRow: React.FC<Props> = ({ maxHeight, lineSpacing = '0.7em' }) => {
  const defaultStyles = {
    maxHeight,
    width: '100%',
    height: '1em',
    marginTop: lineSpacing
  }

  return <div className="text-row-placeholder" style={{ ...defaultStyles }} />
}

export default TextRow
