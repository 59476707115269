import React, { type FC } from 'react'

interface Props {
  children: React.ReactNode
  visible: boolean
}
const Wrapper: FC<Props> = ({ children, visible }) => {
  return <div className={`modal` + (visible ? `` : ` hidden`)}>{children}</div>
}

export default Wrapper
