import React, { type FC } from 'react'
import intl from 'react-intl-universal'

interface PaginatorProps {
  total: number
  current: number
  pageSize: number
  onPageChange: (page: number) => void
}
const Paginator: FC<PaginatorProps> = ({
  total,
  current,
  pageSize,
  onPageChange
}) => {
  if (total <= pageSize) return null

  if (current === 0) current = 1
  const hasNext = current * pageSize < total
  const hasPrev = current > 1
  // const hasFirst = hasPrev
  // const hasLast = hasNext
  const lastPage = Math.ceil(total / pageSize)

  const items = getPaginationItems(current, lastPage)

  return (
    <ul className="pagination">
      <li>
        <a
          className={hasPrev ? '' : 'disabled'}
          onClick={
            hasPrev
              ? (e) => {
                  onPageChange(current - 1)
                }
              : undefined
          }
        >
          {intl.get('pagination.prev')}
        </a>
      </li>

      {items.map((item) => (
        <li key={item}>
          <a
            className={item === current ? `active` : ''}
            onClick={
              item === current
                ? undefined
                : (e) => {
                    onPageChange(item)
                  }
            }
          >
            {item}
          </a>
        </li>
      ))}

      <li>
        <a
          className={hasNext ? '' : 'disabled'}
          onClick={
            hasNext
              ? (e) => {
                  onPageChange(current + 1)
                }
              : undefined
          }
        >
          {intl.get('pagination.next')}
        </a>
      </li>
    </ul>
  )
}

function getPaginationItems(currentPage: number, lastPage: number): number[] {
  const res: number[] = [currentPage]

  if (currentPage + 1 <= lastPage) {
    res.push(currentPage + 1)
  }

  if (currentPage + 2 <= lastPage) {
    res.push(currentPage + 2)
  }

  if (currentPage - 1 >= 1) {
    res.push(currentPage - 1)
  }

  if (currentPage - 2 >= 1) {
    res.push(currentPage - 2)
  }

  return res.sort((a, b) => a - b)
}

export default Paginator
