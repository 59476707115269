import { http } from '../http'
import { type AxiosResponse, HttpStatusCode } from 'axios'

interface LoginParams {
  email: string
  password: string,
  source: string | null
}

interface ConfirmEmailParams {
  id: string
  hash: string
}

interface RegisterParams {
  email: string
  password: string
  name: string
}

export interface ResetPasswordParams {
  email: string
  token: string
  password: string
  password_confirmation: string
}


interface LoggedUser {
  name: string
  email: string
  email_verified_at: string | null
}

/**
 * Войти в систему
 * @param email
 * @param password
 * @param source
 */
export const login = async ({
  email,
  password, source
}: LoginParams): Promise<string> => {
  const response = await http.post<LoginParams, AxiosResponse>('/auth/login', {
    email,
    password,
    source
  })

  return response.data.data.token
}

/**
 * Выйти из системы
 */
export const logout = async (): Promise<boolean> => {
  await http.post('/auth/logout')
  return true
}

/**
 * Зарегистрировать нового пользователя
 * @param email string
 * @param password
 * @param name
 */
export const register = async ({
  email,
  password,
  name
}: RegisterParams): Promise<any> => {
  const response = await http.post<RegisterParams, AxiosResponse>(
    '/auth/register',
    {
      email,
      password,
      name
    }
  )
  return response.data.data
}

export const confirmEmail = async ({id, hash}:ConfirmEmailParams): Promise<any> => {
  const response = await http.get(`email/verify/${id}/${hash}`)
  return response.status === HttpStatusCode.NoContent
}

export const passwordResetRequest = async (email: string): Promise<any> => {
  const response = await http.get(`auth/forgot-password?email=${email}`)
  return response.status === HttpStatusCode.Accepted
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const passwordResetConfirm = async ({email, password, password_confirmation, token}: ResetPasswordParams): Promise<any> => {
  const response = await http.post<ResetPasswordParams, AxiosResponse>(
  'auth/reset-password',
  {
    email,
    password,
    password_confirmation,
    token
  }
  )
  return response.status === HttpStatusCode.NoContent
}

/**
 * Получить информацию о текущем залогиненном пользователе
 */
export const me = async (): Promise<LoggedUser> => {
  const response = await http.get('auth/me')
  return response.data.data
}

export type { LoginParams, RegisterParams, LoggedUser, ConfirmEmailParams }
