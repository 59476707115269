import React, { type FC } from 'react'
import {
  IconFileDollar,
  IconListCheck,
  IconReceiptRefund,
  IconWallet,
  IconWorldUpload
} from '@tabler/icons-react'
import { NavLink } from 'react-router-dom'
import { ROUTES } from 'shared/router'
import intl from 'react-intl-universal'
import { onShortenMenuClick } from 'pages/layouts/model'

const SidebarMenu: FC = () => {
  return (
    <ul
      onClick={() => {
        onShortenMenuClick()
      }}
    >
      <li>
        <NavLink to={ROUTES.wallets}>
          <div>
            <IconWallet stroke={1.6} />
          </div>
          <span>{intl.get('menu.wallets')}</span>
        </NavLink>
      </li>

      <li>
        <NavLink to={ROUTES.invoices}>
          <div>
            <IconFileDollar stroke={1.6} />
          </div>
          <span>{intl.get('menu.invoices')}</span>
        </NavLink>
      </li>

      <li>
        <NavLink to={ROUTES.operations}>
          <div>
            <IconListCheck stroke={1.6} />
          </div>
          <span>{intl.get('menu.operations')}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={ROUTES.outcomes}>
          <div>
            <IconReceiptRefund stroke={1.6} />
          </div>
          <span>{intl.get('menu.outcomes')}</span>
        </NavLink>
      </li>
      <li>
        <NavLink to={ROUTES.webhooks}>
          <div>
            <IconWorldUpload stroke={1.6} />
          </div>
          <span>{intl.get('menu.webhooks')}</span>
        </NavLink>
      </li>
    </ul>
  )
}

export default SidebarMenu
