import React, { type FC } from 'react'

const ArrowLeft: FC = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="9"
        transform="rotate(-90 12 12)"
        stroke="#4B465C"
        strokeWidth="1.5"
      />
      <circle
        cx="12"
        cy="12"
        r="9"
        transform="rotate(-90 12 12)"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path d="M8 12L12 16" stroke="#4B465C" strokeWidth="1.5" />
      <path
        d="M8 12L12 16"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path d="M8 12L16 12" stroke="#4B465C" strokeWidth="1.5" />
      <path
        d="M8 12L16 12"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path d="M12 8L8 12" stroke="#4B465C" strokeWidth="1.5" />
      <path
        d="M12 8L8 12"
        stroke="white"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
    </svg>
  )
}

export default ArrowLeft
