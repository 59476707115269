import { useUnit } from 'effector-react'
import {
  $outcomePaginatorPage,
  $outcomePaginatorPageSize,
  $outcomePaginatorTotal,
  $outcomes,
  loadOutcomesListFx
} from './store'
import { type UseOutcomesProps } from 'entities/outcome/model'

export const useOutcomes = (): UseOutcomesProps => {
  const isLoading = useUnit(loadOutcomesListFx.pending)
  const outcomes = useUnit($outcomes)

  const total = useUnit($outcomePaginatorTotal)
  const perPage = useUnit($outcomePaginatorPageSize)
  const page = useUnit($outcomePaginatorPage)

  return { outcomes, isLoading, total, perPage, page }
}
