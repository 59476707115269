import React, { type FC } from 'react'
import intl from 'react-intl-universal'

interface Props {
  onChange: () => void
  checked: boolean
  size?: 'default' | 'small'
  color?: 'green' | 'default'
  onLabel?: string
  offLabel?: string
}
const Switch: FC<Props> = ({
  checked,
  onChange,
  size = 'default',
  color = 'default',
  onLabel,
  offLabel
}) => {
  const classes =
    'input-checkbox' +
    (size === 'small' ? ' input-checkbox-small' : '') +
    (color === 'green' ? ' input-checkbox-green' : '')

  return (
    <label
      className={onLabel !== null || offLabel !== null ? 'text-nowrap' : ''}
    >
      <input
        type="checkbox"
        className={classes}
        checked={checked}
        onChange={onChange}
      />
      <span>
        <span></span>
      </span>
      {onLabel !== null && onLabel !== undefined && checked && (
        <span>{intl.get(onLabel)}</span>
      )}
      {offLabel !== null && offLabel !== undefined && !checked && (
        <span>{intl.get(offLabel)}</span>
      )}
    </label>
  )
}

export default Switch
