import { combine, createEvent, createStore } from 'effector'
import { $wallets } from 'entities/wallet/model'
import { appDomain } from 'shared/model'
import { api } from 'shared/api'
import { type Outcome } from 'shared/types'

export const onCloseModal = createEvent()
export const onOpenModal = createEvent<{ token: string; network: string }>()

export const $withdrawToken = createStore<string | null>(null)
export const $withdrawNetwork = createStore<string | null>(null)

export const $withdrawWallet = combine(
  $wallets,
  $withdrawNetwork,
  (wallets, withdrawNetwork) => {
    if (withdrawNetwork === null) return null
    const wallet = wallets.find((w) => w.network === withdrawNetwork)
    if (wallet !== undefined) return wallet
    return null
  }
)

export const $subwallet = combine(
  $withdrawWallet,
  $withdrawToken,
  (wallet, token) => {
    if (wallet === null || token === null) return null
    const b = wallet.balances.find((b) => b.token === token)
    return b ?? null
  }
)

export const $maxAmount = $subwallet.map((s) => {
  return s == null ? 0 : parseFloat((s.amount - s.frozen_amount).toFixed(8))
})
export const $withdrawModalVisible = $withdrawWallet.map((w) => w !== null)
$withdrawToken.on(onCloseModal, (_) => null)
$withdrawNetwork.on(onCloseModal, (_) => null)
$withdrawToken.on(onOpenModal, (_, d) => d.token)
$withdrawNetwork.on(onOpenModal, (_, d) => d.network)

export const createWithdrawFx = appDomain.createEffect(
  api.outcome.createWithdraw
)

export const $createWithdrawPending = createWithdrawFx.pending

export const $withdraw = appDomain.createStore<Outcome | null>(null)
$withdraw.on(createWithdrawFx.doneData, (_, outcome) => outcome)
$withdraw.on(onOpenModal, (_) => null)

export const onConfirmWithdraw = appDomain.createEvent()
export const confirmWithdrawFx = appDomain.createEffect(
  api.outcome.confirmWithdraw
)

export const $withdrawConfirmed = appDomain.createStore(false)
$withdrawConfirmed.on(onOpenModal, (_, d) => false)
$withdrawConfirmed.on(confirmWithdrawFx.done, (_) => true)

export const $confirmWithdrawPending = confirmWithdrawFx.pending
