import { http } from 'shared/api/http'
import { type AxiosResponse, HttpStatusCode } from 'axios'
import type * as types from 'shared/types'

export interface CreateMerchantParams {
  title: string
  website: types.Url
  success_url?: types.Url
  failed_url?: types.Url
  webhook_url?: types.Url
  allowed_tokens?: []
}

export type UpdateMerchantParams = CreateMerchantParams & { uuid: types.Uuid }

const BASE_URL = 'merchants'

/**
 * Получить список мерчантов пользователя
 */
export const getAll = async (): Promise<types.IMerchant[]> => {
  const response = await http.get(BASE_URL)
  return response.data.data
}

/**
 * Получить информацию о мерчанте
 * @param uuid
 */
export const getOne = async (uuid: types.Uuid): Promise<types.IMerchant> => {
  const response = await http.get(`${BASE_URL}/${uuid}`)
  return response.data.data
}

/**
 * Закрыть торговую точку
 * @param uuid
 */
export const close = async (uuid: types.Uuid): Promise<boolean> => {
  const response = await http.delete<types.Uuid, AxiosResponse>(
    `${BASE_URL}/${uuid}`
  )
  return response.status === HttpStatusCode.NoContent
}

export const moderate = async (uuid: types.Uuid): Promise<boolean> => {
  const response = await http.patch<types.Uuid, AxiosResponse>(
    `${BASE_URL}/${uuid}/moderate`
  )
  return response.status === HttpStatusCode.Accepted
}

/**
 * Создать торговую точку
 * @param params
 */
export const create = async (
  params: CreateMerchantParams
): Promise<types.Uuid> => {
  const response = await http.post<CreateMerchantParams, AxiosResponse>(
    BASE_URL,
    params
  )
  return response.data.data.uuid
}

export const update = async (
  params: UpdateMerchantParams
): Promise<types.Uuid> => {
  const response = await http.patch<UpdateMerchantParams, AxiosResponse>(
    BASE_URL + '/' + params.uuid,
    params
  )
  return response.data.data.uuid
}

export interface GetOperationsParams {
  merchantUuid: types.Uuid
  page?: number
  perPage?: number
}

/**
 * Список финансовых операций торговой точки
 */
export const getOperations = async ({
  merchantUuid,
  page,
  perPage
}: GetOperationsParams): Promise<
  types.IPaginatedResponse<types.IMerchantOperation>
> => {
  const params = { page, per_page: perPage }
  const response = await http.get(`${BASE_URL}/${merchantUuid}/operations`, {
    params
  })
  return response.data.data
}

/**
 * Получить секретный ключ торговой точки
 * @param merchantUuid
 */
export const getSecret = async (merchantUuid: types.Uuid): Promise<string> => {
  const response = await http.get(`${BASE_URL}/${merchantUuid}/secret`)
  return response.data.data.secret
}

/***
 * Сгенерировать новый секретный ключ торговой точки
 * @param merchantUuid
 */
export const refreshSecret = async (
  merchantUuid: types.Uuid
): Promise<string> => {
  const response = await http.patch(`${BASE_URL}/${merchantUuid}/secret`)
  return response.data.data.secret
}
